html,
body {
    background-color: #222427 !important;
}

.bigButtonBox {
    width: 100%;
    text-align: center;
    height: 300px;
    display: flex;
    flex-direction: column;
    justify-content: space-around;
}

.tableBox {
    overflow-y: auto;
    margin-top: 10px;
    padding-bottom: 70px;
}

.App-header {
    min-height: 100vh;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    font-size: calc(10px + 2vmin);
    color: white;
}

.navBar {
    background-color: #111 !important;
    width: 100%;
    position: fixed;
    bottom: 0;
}

#ScanDialog {
    position: fixed;
    top: 20px;
    left: calc(10px);
    overflow: hidden;

    width: calc(100vw - 20px);

    z-index: 101;

    transition: all cubic-bezier(0.65, 0.445, 0.005, 0.98) 400ms;

    display: flex;
    flex-direction: column;
    text-align: center;

    border-radius: 10px;
}

.ScanDialogClose {
    pointer-events: none;
    opacity: 0;
    transform: translateY(200px) scale(0.7);
}

#ScanDialogBackdrop {
    position: fixed;
    width: 100vw;
    height: 100vh;
    top: 0;
    left: 0;
    z-index: 100;
    background-color: #0003;

    transition: all cubic-bezier(0.65, 0.445, 0.005, 0.98) 200ms;
}

.ScanDialogBackdropClose {
    pointer-events: none;
    background-color: #0000 !important;
}

#reader {
    margin: 10px;
    padding: 5px !important;
    border-radius: 10px;
    border: 3px solid #333 !important;
}
